<template>
  <RatioContainer :x-aspect="1" :y-aspect="1">
    <div
      class="tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
      aria-hidden
    >
      <img
        v-if="
          !showListContent && bucketIsClassic(bucket) && bucket.is_groover_made
        "
        :src="getMediaUrl('Logo.svg')"
        class="tw-h-full tw-w-full tw-object-cover tw-object-center tw-p-[14%]"
        :class="{
          isSelected,
        }"
        alt=""
        loading="lazy"
      />
      <ImagesMosaic
        v-else-if="pictures.length > 1"
        :pictures="pictures"
        class="tw-h-full tw-w-full tw-rounded-full"
      />
      <img
        v-else-if="pictures.length"
        :src="pictures[0]"
        class="tw-h-full tw-w-full tw-rounded-full tw-object-cover tw-object-center"
        alt=""
        loading="lazy"
      />
      <div v-else>
        <i class="fas fa-bookmark tw-text-base tw-text-white" />
      </div>
      <div
        v-if="!navigating && shouldShowBookmark"
        class="overlay"
        :class="{ hasCurrentInfluencer: infIsInBucket }"
      >
        <transition mode="out-in" name="scale">
          <i
            v-if="!navigating"
            :key="bookmarkIcon"
            class="fa-bookmark tw-text-white"
            :class="bookmarkIcon"
          />
        </transition>
      </div>
    </div>
  </RatioContainer>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import ImagesMosaic from '~/components/images/Mosaic.vue'
import RatioContainer from '~/components/shared/ratioContainer.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useInfluencersStore } from '~/stores/influencers'

import pictureBuildHelper from '~/helpers/images/picture_build'

import type GrooverBucket from '~/helpers/favorites/grooverBucket'
import type Bucket from '~/types/bucket'
import type { StatsV3Influencer } from '~/types/influencer'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    ImagesMosaic,
    RatioContainer,
  },
  props: {
    bucket: {
      type: Object as PropType<Bucket | GrooverBucket>,
      required: true,
    },
    showListContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShowBookmark: {
      type: Boolean,
      required: false,
      default: true,
    },
    influencerIdsToBookmark: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => [],
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    navigating: {
      type: Boolean,
      required: false,
      default() {
        return false
      },
    },
  },
  setup() {
    const { getMediaUrl } = useGetMediaUrl()
    return { getMediaUrl }
  },
  computed: {
    ...mapState(useInfluencersStore, { GET_INFLUENCER_BY_ID: 'GET_BY_ID' }),
    pictures(): string[] {
      return this.influencers.map((influencer) => {
        return pictureBuildHelper({
          kind: 'influencer',
          slug: influencer.has_profile_picture ? influencer.slug : undefined,
          target: 'profile_picture',
          size: '400_400',
        })
      })
    },
    bookmarkIcon(): string {
      return this.infIsInBucket ? 'fas' : 'far'
    },
    influencers(): StatsV3Influencer[] {
      return this.influencerIds.reduce((accumulator, influencerId) => {
        const influencer = this.GET_INFLUENCER_BY_ID(influencerId)

        if (influencer) accumulator.push(influencer)

        return accumulator
      }, [] as StatsV3Influencer[])
    },
    influencerIds(): number[] {
      if (this.bucketIsClassic(this.bucket)) return this.bucket.influencers
      else return this.bucket.influencerIds
    },
    infIsInBucket(): boolean {
      return this.influencerIdsToBookmark.every((influencerId) =>
        this.influencerIds.includes(influencerId),
      )
    },
  },
  methods: {
    bucketIsClassic(bucket: Bucket | GrooverBucket): bucket is Bucket {
      return Object.keys(bucket).includes('id')
    },
  },
})
</script>

<style scoped lang="scss">
.overlay {
  @apply tw-absolute tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-5 tw-opacity-0 tw-transition-all tw-duration-300 tw-ease-in-out;
}
img.isSelected {
  filter: brightness(0) invert(1);
}
</style>
