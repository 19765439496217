<template>
  <div class="mosaicWrapper" aria-hidden>
    <div class="pictureBlock">
      <div v-if="pictures.length > 0" class="imageHolder">
        <img :src="pictures[0]" loading="lazy" alt="" />
      </div>
      <div
        v-if="pictures.length > 3"
        class="imageHolder"
        style="border-top: 1px solid #fff"
      >
        <img :src="pictures[3]" loading="lazy" alt="" />
      </div>
    </div>
    <div v-if="pictures.length > 1" class="pictureBlock">
      <div class="imageHolder">
        <img :src="pictures[1]" loading="lazy" alt="" />
      </div>
      <div
        v-if="pictures.length > 2"
        class="imageHolder"
        style="border-top: 1px solid #fff"
      >
        <img :src="pictures[2]" loading="lazy" alt="" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { PropType } from 'vue'

export default defineComponent({
  props: {
    pictures: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.mosaicWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mosaicWrapper .pictureBlock {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mosaicWrapper .pictureBlock .imageHolder {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.mosaicWrapper .pictureBlock .imageHolder img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}
</style>
